import React from "react";

import useData from "../hooks/useData";
import Layout from "../components/Layout/Layout";
import Home from "../containers/Home";
import SEO from "../components/seo";

const IndexPage = ({ location }) => {
  const pageData = useData();

  return (
    <Layout>
      <SEO
        {...{
          title: pageData.meta_title,
          description: pageData.meta_description,
          lang: pageData.meta_lang || "de",
          meta: pageData.meta_extra
        }}
      />
      <Home location={location.href} />
    </Layout>
  );
};

export default IndexPage;
